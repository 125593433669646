<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Constants from "@/data/Constants";
import { url, required } from "vuelidate/lib/validators";

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  data() {
    return {
      title: "Integrations",
      isAdmin:
        preference.getData(Preference.KEY_ROLE_ID) == Constants.ROLE_OWNER,
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Integration",
          active: true,
        },
      ],
      isLoading: false,
      submitted: false,
      isJiraSyncing: false,
      jiraIsLoading: false,
      isJiraIntegrated: false,
      jiraHostUrl: null,
      consumer_key: null,
      api_version: null,
      time_interval: null,
      private_key: null,
      oauth_verifier: null,
      oauthToken: null,
      oauthTokenSecret: null,
    };
  },
  validations: {
    jiraHostUrl: { required, url },
    consumer_key: { required },
    private_key: { required },
    api_version: { required },
    time_interval: { required },
  },
  computed: {
    jiraSynDisabled() {
      return !this.isJiraIntegrated;
    },
  },
  components: {
    Layout,
    PageHeader,
  },

  methods: {
    addJira() {
      let _this = this;
      _this.jiraIsLoading = true;
      return httpRequest.initJira().then(function (response) {
        _this.jiraIsLoading = false;
        if (response.success) {
          _this.$bvModal.hide("AddJiraModal");
          _this.oauthToken = response.data.oauthToken;
          _this.oauthTokenSecret = response.data.oauthTokenSecret;
          _this.oauthTokenSecret = response.data.oauthTokenSecret;
          preference.setData(
            Preference.KEY_JIRA_ACCESS_TOKEN,
            _this.oauthToken
          );
          preference.setData(
            Preference.KEY_JIRA_ACCESS_TOKEN_SECRET,
            _this.oauthTokenSecret
          );
          preference.setData(Preference.KEY_JIRA_HOST_URL, _this.jiraHostUrl);
          //location.replace(response.data.url)
          window.open(response.data.url, "_blank");
        } else {
          _this.$bvModal.hide("AddJiraModal");
          _this.$swal({
            text: "Jira connection error!",
          });
        }
      });
    },
    addJiraConfig() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let _this = this;
        return httpRequest
          .addJiraConfig(
            _this.jiraHostUrl,
            _this.consumer_key,
            _this.private_key,
            _this.api_version
          )
          .then(function (response) {
            _this.jiraIsLoading = false;
            if (response.success) {
              _this.$bvModal.hide("AddJiraModal");
              _this.$swal({
                text: "Jira connection success!",
              });
              _this.loadInitialJiraConfig();
              _this.loadInitialConfigs();
            } else {
              _this.$bvModal.hide("AddJiraModal");
              _this.$swal({
                text: "Jira connection error!",
              });
            }
          });
      }
    },

    // Function to add screenshot time interval configurations
    addScrrenShotTimeIntervalConfigs() {
      // Set 'submitted' flag to true
      this.submitted = true;
      // Stop here if the form is invalid
      this.$v.$touch();

      // Check if the form is invalid
      if (this.$v.$invalid) {
        // If invalid, return without further execution
        return;
      } else {
        // If valid, proceed with the operation
        let _this = this;
        // Check if the time interval is set to 'random'
        if (_this.time_interval == "random") {
          // If set to 'random', generate a random number between 1 and 3
          _this.time_interval = 0;
        }
        // Make an HTTP request to add screenshot time interval configurations
        return httpRequest
          .addScrrenShotTimeIntervalConfigs(_this.time_interval)
          .then(function (response) {
            // Once the request is completed
            _this.jiraIsLoading = false;
            // Check if the response indicates success
            if (response.success) {
              // If successful, hide the modal and show success message
              _this.$bvModal.hide("AddJiraModal");
              _this.$swal({
                text: "Screenshot interval time set success!",
              });
              // Reload initial Jira configurations and initial configurations
              _this.loadInitialJiraConfig();
              _this.loadInitialConfigs();
            } else {
              // If unsuccessful, hide the modal and show error message
              _this.$bvModal.hide("AddJiraModal");
              _this.$swal({
                text: "Screenshot interval time set error!",
              });
            }
          });
      }
    },

    // Function to load initial data
    loadInitialData() {
      // Assign reference to 'this'
      let _this = this;
      // Set loading flag to true
      _this.isLoading = true;
      // Retrieve user ID from preferences
      let userId = preference.getData(Preference.KEY_USER_ID);
      // Make HTTP request to get integration vendors
      return httpRequest
        .getIntegrationVendors(userId)
        .then(function (response) {
          // Once the request is completed
          _this.isLoading = false;
          // Check if response indicates success
          if (response.success) {
            // If successful, mark Jira integration as true
            _this.isJiraIntegrated = true;
          }
        });
    },

    loadInitialJiraConfig() {
      let _this = this;
      _this.isLoading = true;
      return httpRequest.getJiraConfig().then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          _this.isJiraIntegrated = true;
          _this.jiraHostUrl = response.data.jira_url;
          _this.consumer_key = response.data.consumer_key;
          _this.private_key = response.data.private_key;
          _this.api_version = response.data.jira_api_version;
        }
      });
    },

    loadInitialConfigs() {
      let _this = this;
      _this.isLoading = true;
      httpRequest.getConfigs().then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          if (response.data.time_interval == 0) {
            _this.time_interval = "random";
          } else {
            _this.time_interval = response.data.time_interval;
          }
        }
      });
    },
    syncJira() {
      let _this = this;
      _this.isJiraSyncing = true;
      let userId = preference.getData(Preference.KEY_USER_ID);
      let jiraHostUrl = preference.getData(Preference.KEY_JIRA_HOST_URL);
      return httpRequest
        .synJira(userId, jiraHostUrl, _this.api_version)
        .then(function (response) {
          _this.isJiraSyncing = false;
          if (response.success) {
            _this.$swal({
              text: "Jira Syncing Successful",
            });
          } else {
            _this.$swal({
              text: response.message,
            });
          }
        });
    },
  },

  async created() {
    await this.loadInitialData();
    if (this.isAdmin) {
      await this.loadInitialJiraConfig();
      await this.loadInitialConfigs();
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-3">
        <div class="card mb-4">
          <div class="card-body">
            <div class="report-form-row">
              <div class="report-search">
                <b-button block v-if="isAdmin" class="customs-bg-color" variant="primary" v-b-modal.AddJiraModal
                  data-toggle="tooltip" data-placement="top" title="Integrate Jira"><i class="ri-add-line"
                    style="vertical-align: bottom"></i>
                  ADD JIRA
                </b-button>
                <b-button block variant="primary" class="customs-bg-color" v-on:click="addJira()"
                  v-show="isJiraIntegrated">
                  <b-spinner style="width: 1.5rem; height: 1.5rem" label="Text Centered"
                    v-if="jiraIsLoading"></b-spinner>
                  <i class="ri-links-line" style="vertical-align: bottom"></i>
                  CONNECT JIRA
                </b-button>
                <b-button block variant="primary" class="customs-bg-color" v-on:click="syncJira()"
                  v-show="!jiraSynDisabled">
                  <b-spinner style="width: 1.5rem; height: 1.5rem" label="Text Centered"
                    v-if="isJiraSyncing"></b-spinner>
                  <i class="ri-refresh-line" style="vertical-align: bottom"></i>
                  SYNC
                </b-button>
              </div>
            </div>
            <div class="row report-form-row mt-2">
              <div class="font-weight-bold font-size-16 ml-2" v-show="!isAdmin && !isJiraIntegrated"
                style="color: #ff7c47">
                JIRA isn't configured yet, Please contact with your Admin.
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-3 mb-3" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>

      <div class="col-3">
        <div v-if="isAdmin">
          <div class="align-items-center text-center text-md-left card p-4">
            <form class="form-horizontal" @submit.prevent="">
              <!-- Screenshot interval time input field -->
              <div class="form-group mb-4">
                <label for="time_interval">SCREENSHOT INTERVAL TIME</label>
                <select class="form-control" id="time_interval" v-model="time_interval">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="random">Random</option>
                </select>
              </div>

              <!-- Add button -->
              <div class="mt-4 text-center">
                <b-button variant="primary" size="lg" class="customs-bg-color" type="submit"
                  v-on:click="addScrrenShotTimeIntervalConfigs">Add</b-button>
              </div>

              <!-- Loading spinner -->
              <div class="mt-4 text-center" v-if="jiraIsLoading">
                <div class="text-center mt-3 mb-3">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="text-center mt-3 mb-3" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border-color: #ff7c47;
}

.btn-outline-primary:hover {
  border-color: #bdc5f1 !important;
}

.swal2-styled.swal2-confirm {
  background-color: #ff7c47 !important;
}
</style>
